<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
::-webkit-scrollbar {
width:2px;
height: 5px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
/* -webkit-box-shadow:inset006pxrgba(0,0,0,0.3); */
border-radius:1px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
border-radius:10px;
background:rgba(0,0,0,0.3);
/* -webkit-box-shadow:inset006pxrgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
background:rgba(0,0,0,0.3);
}
</style>
