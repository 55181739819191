import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login',
    name: 'login',
    component:()=> import("@/views/login/index.vue")
  },
  // 普通用户路由(个人)
  {
    path: '/index',
    name: 'index',
    component:()=> import("@/views/index.vue"),
    children:[
      {
        path: 'sale',
        name: 'sale',
        component:()=> import("@/views/sale/index.vue"),
        meta:{
          active:'1',
          title:'我的销售'
        }
      },
      {
        path: '',
        name: 'workBench',
        component:()=> import("@/views/workBench/index.vue"),
        meta:{
          active:'17',
          title:'工作台'
        }
      },
      {
        path: 'grow',
        name: 'grow',
        component:()=> import("@/views/grow/index.vue"),
        meta:{
          active:'18',
          title:'成长'
        }
      },
      {
        path: 'growDetails',
        name: 'details',
        component:()=> import("@/views/grow/details.vue"),
        meta:{
          active:'18',
          title:'成长'
        }
      },
      

      {
        path: 'member',
        name: 'member',
        component:()=> import("@/views/member/index.vue"),
        meta:{
          active:'2',
          title:'我的会员'
        }
      },
      {
        path: 'experience',
        name: 'experienceCard',
        component:()=> import("@/views/experienceCard/index.vue"),
        meta:{
          active:'3',
          title:'我的体验卡'
        }
      },
      {
        path: 'entity',
        name: 'entityCard',
        component:()=> import("@/views/entityCard/index.vue"),
        meta:{
          active:'4',
          title:'我的实体卡'
        }
      },
      {
        path: 'settings',
        name: 'personalSettings',
        component:()=> import("@/views/personalSettings/index.vue"),
        meta:{
          active:'5',
          title:'个人设置'
        }
      },
      {
        path: 'withdrawManage',
        name: 'withdrawManage',
        component:()=> import("@/views/withdrawManage/index.vue"),
        meta:{
          active:'6',
          title:'提现管理'
        }
      },
      {
        path: 'code',
        name: 'code',
        component:()=> import("@/views/code/index.vue"),
        meta:{
          active:'7',
          title:'我的二维码'
        }
      },
       // 团队管理
      {
        path: 'team',
        name: 'teamManage',
        component:()=> import("@/views/teamManage/index.vue"),
        meta:{
          active:'8',
          title:'团队管理',
          
        }
      },
      {
        path: 'vipEntityCard',
        name: 'vipEntityCard',
        component:()=> import("@/views/teamManage/vipEntityCard.vue"),
        meta:{
          active:'9',
          title:'vip实体卡',
          
        }
      },
      {
        path: 'teamExperience',
        name: 'teamExperience',
        component:()=> import("@/views/teamManage/teamExperience.vue"),
        meta:{
          active:'10',
          title:'体验卡',
        }
      },

      // 销售管理
      {
        path: 'saleManage',
        name: 'saleManage',
        component:()=> import("@/views/saleManage/index.vue"),
        meta:{
          active:'11',
          title:'销售汇总',
        }
      },
      {
        path: 'profitSummary',
        name: 'profitSummary',
        component:()=> import("@/views/saleManage/profitSummary.vue"),
        meta:{
          active:'12',
          title:'利润汇总',
        }
      },
      {
        path: 'transactionFlow',
        name: 'transactionFlow',
        component:()=> import("@/views/saleManage/transactionFlow.vue"),
        meta:{
          active:'13',
          title:'交易流水',
        }
      },
      {
        path: 'withdrawalManage',
        name: 'withdrawalManage',
        component:()=> import("@/views/saleManage/withdrawalManage.vue"),
        meta:{
          active:'14',
          title:'提现管理',
        }
      },
      {
        path: 'pullRanking',
        name: 'pullRanking',
        component:()=> import("@/views/saleManage/pullRanking.vue"),
        meta:{
          active:'15',
          title:'拉新排行',
        }
      },
      {
        path: 'largeRanking',
        name: 'largeRanking',
        component:()=> import("@/views/saleManage/largeRanking.vue"),
        meta:{
          active:'16',
          title:'大排行',
        }
      },
    ]
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    (document as any).title = to.meta.title;
  }
  
  if (!localStorage.getItem('token') && to.fullPath != '/login') {
    next({path:"/login"});
  }else{
    next()
  }
});

export default router
